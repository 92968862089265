import containsLowercase from '../containsLowercase';
import containsNumber from '../containsNumber';
import containsUppercase from '../containsUppercase';
import isMinLength from '../isMinLength';

/**
 * Determine if the string based on our requirements for a
 * strong password.
 *
 * @param password {string} Password string in question
 */
export default function isValidPassword(password: string) {
    const errors = new Map<string, string>();

    if (!isMinLength(password, 8)) {
        errors.set('minLength', 'Password must be at least 8 characters');
    }

    if (!containsLowercase(password)) {
        errors.set('containsLowercase', "Password doesn't contain a lowercase character");
    }

    if (!containsUppercase(password)) {
        errors.set('containsUppercase', "Password doesn't contain an uppercase character");
    }

    if (!containsNumber(password)) {
        errors.set('containsNumber', "Password doesn't contain a number");
    }

    return {
        errors,
        hasErrors: errors.size > 0,
    };
}
