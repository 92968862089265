/**
 * Checks if the input value is a string and either returns it
 * or null in the case of an empty string.
 */
export default function stringOrNull(val: unknown): string | null | unknown {
    if (typeof val === 'string') {
        return val.length > 0 ? val : null;
    }

    return val;
}
