import { object, string } from 'zod';

export default function useEnv() {
    const envSchema = object({
        REACT_APP_GOOGLE_MAP_API_KEY: string().min(1),
        REACT_APP_API_SERVICE_ENDPOINT: string().min(1),
        REACT_APP_IDENTITY_SERVICE_ENDPOINT: string().min(1),
        REACT_APP_RESIDENT_PORTAL_LOGIN_URL: string().min(1),
        REACT_APP_APPLICATION_FORM_LOGIN_URL: string().min(1),
        REACT_APP_OWNER_PORTAL_LOGIN_URL: string().min(1),
        REACT_APP_SENTRY_DSN: string().optional(),
    });

    return envSchema.parse(process.env);
}
