import { LoadingRing, Modal } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { lazy, LazyExoticComponent, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import classes from './AppModals.module.scss';

const Welcome = lazy(() => import('./Private/Welcome'));

const cx = classNames.bind(classes);

export const privateModals = {
    welcome: Welcome,
};

export const publicModals = {};

const allModals = {
    ...privateModals,
    ...publicModals,
};

export type AppModalNames = keyof typeof allModals;

const getModalComponent = (
    modals: Record<string, LazyExoticComponent<() => JSX.Element>>,
    name: string | null
) => {
    if (name === null) {
        return null;
    }

    if (Object.hasOwn(modals, name)) {
        return modals[name];
    }

    return null;
};

export default function AppModals() {
    // TODO: after updating react-router-typesafe-routes package to v1.1.0, use useTypedSearchParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isLoggedIn } = useAuth();
    const appModal = searchParams.get('modal');
    const contents = isLoggedIn ? allModals : publicModals;
    const ContentComponent = getModalComponent(contents, appModal);

    if (ContentComponent === null) {
        return null;
    }

    return (
        <Modal
            className={cx(`modal--${appModal}`)}
            id={classes['app-modal']}
            noPadding={['welcome'].includes(appModal ?? '')}
            onClose={() => {
                setSearchParams((params) => {
                    params.delete('modal');
                    return params;
                });
            }}
            showModal
        >
            <Suspense fallback={<LoadingRing />}>
                <ContentComponent />
            </Suspense>
        </Modal>
    );
}
