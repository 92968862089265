import { cloneElement, forwardRef } from 'react';

import { SearchIcon } from '../../icons';
import BaseInput, { BaseInputProps } from '../BaseInput';

export type SearchInputProps = Omit<BaseInputProps, 'prefix'>;

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
    ({ clearable = false, placeholder = 'Search...', suffix, ...rest }, ref) => {
        /**
         * This is needed to get around some uglyness with TS. TS gets confused
         * with the BaseInput union type which requires a lot of extra and
         * explicit markup. Essentially cloneElement bypasses TS's complaining.
         * But this if ok because we know what we are doing... right?
         */
        return cloneElement(
            <BaseInput
                {...rest}
                placeholder={placeholder}
                prefix={({ className, testId }) => (
                    <div className={className} data-testid={testId}>
                        <SearchIcon
                            aria-hidden
                            data-testid="search-prefix"
                            height={16}
                            width={16}
                        />
                    </div>
                )}
                ref={ref}
                type="text"
            />,
            {
                clearable,
                suffix: clearable ? undefined : suffix,
            }
        );
    }
);

export default SearchInput;
