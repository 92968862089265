import { ReactNode } from 'react';

import { Navigation } from '../../Navigation';
import classes from './AuthLayout.module.scss';

interface AuthLayoutProps {
    children?: ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
    return (
        <div className={classes.wrapper}>
            <Navigation />
            {children}
        </div>
    );
}
