import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ErrorMessage, FormGroup, TextInput, useToast } from '@keyliving/component-lib';
import { User } from '@keyliving/shared-types';
import { SubmitHandler, useForm } from 'react-hook-form';

import { SUPPORT_EMAIL } from '../../../constants';
import { useAppDispatch } from '../../../hooks/redux';
import { authApi } from '../../../redux/modules/api';
import { updateUserDetails } from '../../../redux/modules/auth';
import { useUpdateUserMutation } from '../../../redux/modules/user/userService';
import { editProfileValidation, Inputs } from './validation';

interface EditProfileFormProps {
    user: User;
}

export default function EditProfileForm({ user }: EditProfileFormProps) {
    const [updateUser, { isError, isLoading }] = useUpdateUserMutation();
    const toast = useToast();
    const dispatch = useAppDispatch();

    const {
        formState: { errors: validationErrors },
        handleSubmit,
        register,
    } = useForm<Inputs>({
        resolver: zodResolver(editProfileValidation),
    });

    const onSubmit: SubmitHandler<Inputs> = async ({ email, name, phone_number }) => {
        const formattedEmail = email.toLowerCase().trim();

        const resp = await updateUser({
            email: formattedEmail,
            name,
            phoneNumber: phone_number, // overwrite phone because of API inconsistency
            id: user.id,
        });

        if ('error' in resp) {
            toast.error(resp.error.message || 'Something went wrong');
        }

        // We only have data if is successful
        if ('data' in resp) {
            // Update user in state
            dispatch(
                updateUserDetails({
                    email: formattedEmail,
                    name,
                    phone_number,
                })
            );

            // See note in userService about invalidateTags
            dispatch(authApi.util.invalidateTags([{ type: 'User', id: 'me' }]));

            toast.success('Profile Updated');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
                defaultValue={user.name || ''}
                errors={validationErrors}
                label="Full Name"
                placeholder="Name"
                {...register('name')}
            />
            <TextInput
                defaultValue={user.phone_number || ''}
                errors={validationErrors}
                label="Phone Number (optional)"
                placeholder="Phone Number"
                prefix="+1"
                {...register('phone_number')}
            />
            <TextInput
                autoCapitalize="none"
                autoCorrect="off"
                defaultValue={user.email}
                errors={validationErrors}
                inputMode="email"
                label="Email"
                placeholder="Email"
                {...register('email')}
            />

            {isError && (
                <FormGroup>
                    <ErrorMessage
                        message={`Could not update profile. Please contact ${SUPPORT_EMAIL} for help.`}
                        name="form-error"
                    />
                </FormGroup>
            )}

            <FormGroup>
                <Button isLoading={isLoading} size="md" theme="tertiary" type="submit">
                    Update
                </Button>
            </FormGroup>
        </form>
    );
}
