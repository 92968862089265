import { IconProps } from './types';

export default function HomeInsuranceIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M14.741 16.883 14.2 22.5l3.65-1.5 3.65 1.5-.557-5.788M9.5 19.54h-3a1.5 1.5 0 0 1-1.5-1.5v-7.5M2 9.04l8.781-7.43a1.5 1.5 0 0 1 1.938 0L18.5 6.5M17 1.54h3v3"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <circle
                cx="17.75"
                cy="13.54"
                fill="none"
                r="4.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <circle
                cx="17.75"
                cy="13.54"
                fill="none"
                r="1.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
