import {
    ChangeEventHandler,
    FocusEventHandler,
    forwardRef,
    InputHTMLAttributes,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useMemo,
    useRef,
} from 'react';

import { CloseIcon } from '../../icons';
import mergeRefs from '../../lib/mergeRefs';
import setNativeValue from '../../lib/setNativeValue';
import IconButton from '../IconButton';
import InputGroup, { InputGroupProps } from '../InputGroup';
import InputWrapper from '../InputWrapper';
import classes from './BaseInput.module.scss';

const PREFIX_TEST_ID = 'input-prefix';

type NativeInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'prefix'>;

export interface CommonProps
    extends NativeInputProps,
        Omit<InputGroupProps, 'children' | 'className'> {
    /** Default value of the input */
    defaultValue?: string;
    /** Is the input disabled */
    disabled?: boolean;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** Function to be called on the onChange event */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /** Placeholder text of the input */
    placeholder?: string;
    /** A string or Icon to be added to the start of the input */
    // prefix?: string | ReactElement<IconProps>;
    prefix?:
        | ReactNode
        | ((props: { className: string; testId: typeof PREFIX_TEST_ID }) => ReactNode);
    /** Set required attribute of the input and * beside label */
    required?: boolean;
}

export interface ClearableProps extends CommonProps {
    /** Should an `x` button be displayed to clear the input */
    clearable: true;
    /** Function to be called when the clear button is clicked */
    onClear?: MouseEventHandler<HTMLButtonElement>;
    /** A ReactNode to be added to the end of the input */
    suffix?: never;
}

export interface NotClearableProps extends CommonProps {
    clearable?: false;
    onClear?: MouseEventHandler<HTMLButtonElement>;
    suffix?: ReactNode;
}

export type BaseInputProps = ClearableProps | NotClearableProps;

const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
    (
        {
            caption,
            clearable = false,
            defaultValue,
            disabled = false,
            errors,
            hideLabel,
            label,
            name,
            onBlur,
            onChange,
            onClear,
            placeholder,
            prefix,
            required,
            secondaryLabel,
            suffix,
            ...rest
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);

        const handleClear = useCallback<MouseEventHandler<HTMLButtonElement>>(
            (e) => {
                if (disabled || !clearable) {
                    return;
                }

                if (inputRef.current) {
                    setNativeValue(inputRef.current, '');
                    inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
                }

                if (onClear) {
                    onClear(e);
                }
            },
            [disabled, onClear, clearable]
        );

        const inputSuffix = useMemo(() => {
            if (clearable) {
                return (
                    <IconButton
                        aria-label="Clear Input"
                        fill={null}
                        icon={<CloseIcon />}
                        onClick={handleClear}
                        rounded
                        size="xs"
                        theme={null}
                    />
                );
            }

            return suffix;
        }, [clearable, handleClear, suffix]);

        const inputPrefix = useMemo(() => {
            if (!prefix) {
                return null;
            }

            if (typeof prefix === 'string' || typeof prefix === 'number') {
                return (
                    <div className={classes.prefix} data-testid={PREFIX_TEST_ID}>
                        {prefix}
                    </div>
                );
            }

            if (typeof prefix === 'function') {
                return prefix({ className: classes.prefix, testId: PREFIX_TEST_ID });
            }

            /**
             * Commenting out for now as this is too restrictive. We need to be able to pass
             * any ReactNode. The origional intent here was to ensure aria-hidden was set
             * and to create consistent sizing of icons on inputs. BUT we need a way to
             * determine if the ReactNode passed is an SVG. Not 100% sure how to do
             * this at the moment. Seeing as we have only used this once
             * (SearchInput), it's not a high priority.
             */
            // return cloneElement(prefix, {
            //     width: 16,
            //     height: 16,
            //     'aria-hidden': true,
            // });

            return prefix;
        }, [prefix]);

        return (
            <InputGroup
                caption={caption}
                errors={errors}
                hideLabel={hideLabel}
                label={label}
                name={name}
                required={required}
                secondaryLabel={secondaryLabel}
            >
                {({ errorId, isInvalid, labelId }) => (
                    <InputWrapper disabled={disabled} invalid={isInvalid}>
                        {prefix ? inputPrefix : null}
                        <input
                            aria-describedby={errorId}
                            aria-invalid={isInvalid}
                            aria-labelledby={labelId}
                            className={classes.input}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            id={name}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder={placeholder}
                            ref={mergeRefs([inputRef, ref])}
                            required={required}
                            {...rest}
                        />
                        {inputSuffix ? (
                            <div className={classes.suffix} data-testid="input-suffix">
                                {inputSuffix}
                            </div>
                        ) : null}
                    </InputWrapper>
                )}
            </InputGroup>
        );
    }
);

export default BaseInput;
