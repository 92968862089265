import { withProfiler, wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';

import HydrationGate from './components/HydrationGate';
import AppModals from './components/Modals/AppModals';
import appRoutes from './routes/routes';

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
    const routes = useSentryRoutes(appRoutes);

    return (
        <HydrationGate>
            <>
                {routes}
                <AppModals />
            </>
        </HydrationGate>
    );
}

export default withProfiler(App);
