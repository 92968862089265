import { useClickOutside } from '@keyliving/component-lib';
import classnames from 'classnames/bind';
import { ReactNode, useRef, useState } from 'react';

import classes from './Nav.module.scss';

const cx = classnames.bind(classes);

export interface DropdownItem {
    label: string;
    url: string;
}

interface RenderItemProps {
    activeClassName: string;
    className: string;
    item: DropdownItem;
    key: string;
    role: string;
}

interface DropdownProps {
    className?: string;
    id: string;
    items: DropdownItem[];
    label: ReactNode;
    renderItem?: (props: RenderItemProps) => ReactNode;
}

export default function Dropdown({ className, id, items, label, renderItem }: DropdownProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useClickOutside(dropdownRef, () => {
        setIsOpen(false);
    });

    return (
        <div className={cx(classes.dropdown, className)} ref={dropdownRef} role="menu">
            <button
                aria-controls={id}
                aria-expanded={isOpen}
                aria-haspopup="true"
                className={classes.nav__item}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                {label}
                <div className={cx(classes.chevron, 'icon', 'w-icon-dropdown-toggle')} />
            </button>
            <div
                className={cx('dropdown-menu', {
                    'dropdown-menu--open': isOpen,
                })}
                id={id}
            >
                {items.map((item) => {
                    const { label, url } = item;
                    const key = `${id}-${label}`;
                    const role = 'menuitem';

                    if (renderItem) {
                        return renderItem({
                            item,
                            className: classes.nav__item,
                            activeClassName: classes['nav__item--active'],
                            key,
                            role,
                        });
                    }

                    return (
                        <a className={classes.nav__item} href={url} key={key} role={role}>
                            {label}
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
