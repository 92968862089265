import { route, string, types, union } from 'react-router-typesafe-routes/dom';

export const AUTH_MODAL_FRAGMENT = route('', {
    searchParams: { authForm: union('register', 'login', 'forgot') },
});

// TODO: Once PR merged to upgrade react router, use zod to validate these and us the types helper to add to all routes
// const APP_MODAL_PARAMS = { modal: oneOfType('welcome') };

const URLS = {
    Account: route('account', types(AUTH_MODAL_FRAGMENT), { EditProfile: route('edit-profile') }),
    Homes: route('homes', types(AUTH_MODAL_FRAGMENT), {
        City: route(':city', types(AUTH_MODAL_FRAGMENT), {
            Building: route(':street', types(AUTH_MODAL_FRAGMENT), {
                Asset: route(
                    ':number',
                    types({
                        searchParams: {
                            initial: string(),
                            monthly: string(),
                        },
                    })(AUTH_MODAL_FRAGMENT)
                ), // The unit #, Main Floor, Basement etc.
            }),
        }),
    }),
    // TODO: This is legacy, we should redirect and remove this route in future
    ListingDetails: route(
        'ListingDetails',
        types({
            searchParams: {
                suiteNo: string(),
                buildingName: string(),
            },
        })(AUTH_MODAL_FRAGMENT)
    ),
    Logout: route('logout'),
    NewPassword: route('new-password/:token'),
};

export default URLS;
