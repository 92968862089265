import { Button } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

import privateIllustration from '../../assets/images/protected.svg';
import { AUTH_MODAL_FRAGMENT } from '../../routes/urls';
import { AuthModal } from '../Auth';
import classes from './PrivateRoute.module.scss';

const cx = classNames.bind(classes);

export default function PrivateRouteFallback() {
    const [, setTypedSearchParams] = useTypedSearchParams(AUTH_MODAL_FRAGMENT);

    return (
        <>
            <div className={classes.wrapper}>
                <div>
                    <div className={cx('container', 'content')}>
                        <img
                            alt="Locked Door"
                            className={classes.image}
                            src={privateIllustration}
                        />
                        <h1 className={classes.title}>This door is locked</h1>
                        <div className={classes.description}>
                            <p>Login to view this page</p>
                        </div>
                    </div>
                    <Button
                        className={classes.cta}
                        onClick={() => {
                            setTypedSearchParams({ authForm: 'login' });
                        }}
                        size="sm"
                        theme="tertiary"
                    >
                        Login
                    </Button>
                </div>
            </div>
            <AuthModal />
        </>
    );
}
