import { IconProps } from './types';

export default function AirConditioningIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M12 .75v7.5M15.75 2.25l-3.75 3-3.75-3M2.257 6.375l6.495 3.75M5.431 3.877l.723 4.748-4.473 1.748M2.257 17.625l6.495-3.75M1.681 13.627l4.473 1.748-.723 4.748M12 23.25v-7.5M8.25 21.75l3.75-3 3.75 3M21.743 17.625l-6.495-3.75M18.569 20.123l-.723-4.748 4.473-1.748M21.743 6.375l-6.495 3.75M22.319 10.373l-4.473-1.748.723-4.748M12 8.25l-3.248 1.875v3.75L12 15.75l3.248-1.875v-3.75L12 8.25z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
