import classNames from 'classnames/bind';
import { ReactNode } from 'react';

import styles from './PageContainer.module.scss';

const cx = classNames.bind(styles);

interface indexProps {
    children?: ReactNode;
    className?: string;
}

export default function PageContainer({ children, className }: indexProps) {
    return <div className={cx('container', className)}>{children}</div>;
}
