import { IconProps } from './types';

export default function StoveIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M22.25.75H1.75v22.5h20.5V.75ZM.75.75h22.5M.75 23.25h22.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M17.29 11H6.71a.96.96 0 0 0-.96.96v7.08c0 .53.43.96.96.96h10.58c.53 0 .96-.43.96-.96v-7.08a.96.96 0 0 0-.96-.96ZM9.25 14h5.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M5.255 4.87a.375.375 0 0 1 0-.75M5.255 4.87a.375.375 0 0 0 0-.75M8.755 4.87a.375.375 0 0 1 0-.75M8.755 4.87a.375.375 0 0 0 0-.75"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M1.75 8h20.5M13.5 4.5h5.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
