import { forwardRef, useState } from 'react';

import { HideIcon, ShowIcon } from '../../icons';
import BaseInput, { BaseInputProps } from '../BaseInput';
import IconButton from '../IconButton';

export type PasswordInputProps = Omit<BaseInputProps, 'suffix' | 'prefix' | 'type'>;

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
    ({ disabled, label, name, placeholder = 'Password', ...rest }, ref) => {
        const [type, setType] = useState<'text' | 'password'>('password');

        const handleToggle = () => {
            setType(type === 'text' ? 'password' : 'text');
        };

        return (
            <BaseInput
                {...rest}
                autoCapitalize="none"
                autoCorrect="off"
                clearable={false}
                disabled={disabled}
                label={label}
                name={name}
                placeholder={placeholder}
                ref={ref}
                suffix={
                    !disabled ? (
                        <IconButton
                            aria-label={type === 'text' ? 'Hide Password' : 'Show Password'}
                            fill="transparent"
                            icon={type === 'text' ? <HideIcon /> : <ShowIcon />}
                            onClick={handleToggle}
                            rounded
                            size="xs"
                            theme="secondary"
                        />
                    ) : null
                }
                type={disabled ? 'password' : type}
            />
        );
    }
);

export default PasswordInput;
