export enum RA_CHARGE_TYPE {
    FULL = 'FULL', // Full to Owner
    PRORATED = 'PRORATED', // Split Owner & Resident
    FULL_TO_RESIDENT = 'FULL_TO_RESIDENT',
}

export enum RA_CHARGE_STATUS {
    SUBMITTED = 'SUBMITTED',
    COMPLETED = 'COMPLETED',
}

// This matches the structure in the DB
export interface BaseReimbursementAdjustmentItem {
    id: string;
    created_by: string;
    product_id: string;
    type: RA_CHARGE_TYPE;
    should_flow_to_keyliving: boolean;
    status: RA_CHARGE_STATUS;
    building_suite_id: string;
    amount: number;
    description: string | null;
    document_folder_id: string | null;
    invoice_id: string | null;
    receipt_number: string | null;
    created_at: string;
    updated_at: string;
}

// This matches what comes from the GET route after DB joins/manipulation
export interface ReimbursementAdjustmentItem extends BaseReimbursementAdjustmentItem {
    address: {
        unit: string | null;
        street: string;
        city: string;
        name: string | null;
    };
    created_by_user: {
        name: string | null;
        id: string;
    };
    ownership_percentage: number | null;
}
