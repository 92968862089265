import { IconProps } from './types';

export default function HeartIcon({ height = 23, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 23.69 22.5"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M11.85 22.5c-.18 0-.35-.06-.49-.18C9 20.27-2.58 9.77.53 3.55 2.11.38 5.98-.91 9.14.68c.62.31 1.18.71 1.67 1.2l1.04 1.03 1.04-1.03A6.37 6.37 0 0 1 17.42 0c1.72 0 3.33.67 4.54 1.88.49.49.89 1.05 1.2 1.67 3.23 6.46-9.38 17.53-10.83 18.77-.14.12-.31.18-.49.18Z"
                fill="currentColor"
            />
        </svg>
    );
}
