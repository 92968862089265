export const bedroomOptions = [
    { id: 'bedroom_1', label: '1 bedroom' },
    { id: 'bedroom_2', label: '1+1 bedrooms' },
    { id: 'bedroom_3', label: '2 bedrooms' },
    { id: 'bedroom_4', label: '3 bedrooms' },
];

export const bathroomOptions = [
    { id: 'bathroom_1', label: '1 bathroom' },
    { id: 'bathroom_2', label: '2 bathrooms' },
];

export const suiteOptions = [
    {
        id: 'suite_option_1',
        label: 'Hydro Included',
    },
    {
        id: 'suite_option_2',
        label: 'Electricity Included',
    },
    {
        id: 'suite_option_3',
        label: 'Hydro-Electricity Included',
    },
];

export const sortingOptions = [
    {
        id: 'sort_1',
        label: 'Low to High Residency Payment',
    },
    {
        id: 'sort_2',
        label: 'High to Low Residency Payment',
    },
    {
        id: 'sort_3',
        label: 'Number of Bedrooms',
    },
    {
        id: 'sort_4',
        label: 'Residence',
    },
];

export const LIST_OF_AMENITIES = [
    'Small Pets Allowed',
    'Elevator',
    'Park Nearby',
    'Bus Stop Nearby',
    'Metro Station Nearby',
    'Bike Friendly',
    'Bike Stand Nearby',
    'Guest Parking',
    'Concierge on Site',
    'Fitness Center',
    'Garbage Chute',
    'Train Station Nearby',
    'BBQ Allowed on Balcony',
    'Outdoor Swimming Pool',
    'Indoor Swimming Pool',
    'Outdoor Parking',
    'Indoor Parking',
    'Community Garden',
    'Terrace',
    "Surveillance Camera's",
    'Doorman',
    '24/7 Access',
    'Private Office',
    'Virtual Office',
    'Large Pets Allowed',
];

export const LIMITER_RATE = 'LIMITER_RATE';

export const ALL_LOCATIONS = ['Toronto', 'Edmonton'];

// Remove when we aren't forcing Available value for specific suite anymore
export const FORCE_AVAILABLE_SUITES = [
    'f93fb4f5-9a55-41fa-a885-b5eb8d147c7b', // 211, 400 Adelaide
    'b96e2be5-07ca-42f0-b954-3135102621c3', // 212, 400 Adelaide
];

// Remove when we aren't forcing values for specific suite anymore
export const FORCE_CONTRACTUAL_RENT = new Map([
    // 211, 400 Adelaide
    ['f93fb4f5-9a55-41fa-a885-b5eb8d147c7b', 2_385.28],
    // 212, 400 Adelaide
    ['b96e2be5-07ca-42f0-b954-3135102621c3', 2_548.07],
]);

export const SUPPORT_EMAIL = 'info@keyown.com';

export const REQUIRED_MSG = 'This field is required';

export const FORCE_MIN_CREDIT_SCORE = new Map([
    // 3801, Old Troup Hwy - Old Troup, Texas
    ['7af258c0-4fa0-4e8a-89c0-fab9ac6f1948', '580'],
]);
