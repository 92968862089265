import { DOCUMENT_TYPE, UserDocument } from '../Document';
import {
    OnboardingApplicantV1,
    OnboardingApplicationDataV1,
    OnboardingApplicationV1,
} from './onboarding-application-v1';
import {
    OnboardingApplicantV2,
    OnboardingApplicationDataV2,
    OnboardingApplicationV2,
} from './onboarding-application-v2';
import { UnlockApplicant, UnlockApplication, UnlockApplicationData } from './unlock-application';
export type { UnlockApplication } from './unlock-application';
export type { OnboardingApplicationV1 } from './onboarding-application-v1';
export type { OnboardingApplicationV2 } from './onboarding-application-v2';

/**
 * The below ApplicationData types are an attempt to give structure
 * to the JSON data that is saved in the DB. It's by
 * no means exhaustive and you may find discrepencies. The next step
 * to ensuring correct data is validating the data with a library
 * like Zod or Joi.
 *
 * These are also duplicated in the services repo
 *
 */

// List of valid application versions
export enum APPLICATION_VERSION {
    V1 = '1',
    V2 = '2',
}

// This application status represents the onboarding client application status
export enum APPLICATION_FORM_STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING_LETTER_OF_EMPLOYMENT = 'PENDING_LETTER_OF_EMPLOYMENT',
    APPROVED = 'APPROVED',
    SUBMITTED = 'SUBMITTED',
    CANCELLED = 'CANCELLED',
    DECLINED = 'DECLINED',
    OTHER = 'OTHER',
    COMPLETED = 'COMPLETED',
}

export enum APPLICANT_STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING_LETTER_OF_EMPLOYMENT = 'PENDING_LETTER_OF_EMPLOYMENT',
    APPROVED = 'APPROVED',
    SUBMITTED = 'SUBMITTED',
    CANCELLED = 'CANCELLED',
    DECLINED = 'DECLINED',
    OTHER = 'OTHER',
    COMPLETED = 'COMPLETED',
}

export enum EMPLOYMENT_STATUS {
    FULL_TIME = 'FULL_TIME',
    PART_TIME = 'PART_TIME',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    CONTRACT = 'CONTRACT',
    UNEMPLOYED = 'UNEMPLOYED',
}

// Comes from - https://github.com/KeyLiving/services/blob/741c5650eaae5f4976ee1a589c69b08ff8e1245d/services/api-service/src/application-form/controller.ts#L90
export interface ApplicationDocument extends UserDocument {
    document: Omit<UserDocument['document'], 'type'> & {
        type: Exclude<DOCUMENT_TYPE, DOCUMENT_TYPE.INVOICE>;
    };
}

export type ApplicationForm = {
    id: string;
    status: APPLICATION_FORM_STATUS;
    building_suite_id: string | null;
    created_at: string; // ISO-8601
    updated_at: string; // ISO-8601
};

export type OnboardingApplication = OnboardingApplicationV1 | OnboardingApplicationV2;

// Type create by joining ApplicationForm and select Applicant fields
export type Application = OnboardingApplication | UnlockApplication;

export type OnboardingApplicationData = OnboardingApplicationDataV1 | OnboardingApplicationDataV2;

export type ApplicationData = UnlockApplicationData | OnboardingApplicationData;

export type OnboardingApplicant = OnboardingApplicantV1 | OnboardingApplicantV2;

export type Applicant = UnlockApplicant | OnboardingApplicant;
