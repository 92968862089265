export enum COUNTRIES {
    AD = 'Andorra',
    AE = 'United Arab Emirates',
    AF = 'Afghanistan',
    AG = 'Antigua And Barbuda',
    AI = 'Anguilla',
    AL = 'Albania',
    AM = 'Armenia',
    AO = 'Angola',
    AQ = 'Antarctica',
    AR = 'Argentina',
    AS = 'American Samoa',
    AT = 'Austria',
    AU = 'Australia',
    AW = 'Aruba',
    AX = 'Aland Islands',
    AZ = 'Azerbaijan',
    BA = 'Bosnia And Herzegovina',
    BB = 'Barbados',
    BD = 'Bangladesh',
    BE = 'Belgium',
    BF = 'Burkina Faso',
    BG = 'Bulgaria',
    BH = 'Bahrain',
    BI = 'Burundi',
    BJ = 'Benin',
    BL = 'Saint Barthelemy',
    BM = 'Bermuda',
    BN = 'Brunei Darussalam',
    BO = 'Bolivia',
    BQ = 'Bonaire Sint Eustatius Saba',
    BR = 'Brazil',
    BS = 'Bahamas',
    BT = 'Bhutan',
    BV = 'Bouvet Island',
    BW = 'Botswana',
    BY = 'Belarus',
    BZ = 'Belize',
    CA = 'Canada',
    CC = 'Cocos Keeling Islands',
    CD = 'Congo Democratic Republic',
    CF = 'Central African Republic',
    CG = 'Congo',
    CH = 'Switzerland',
    CI = "Cote D'Ivoire",
    CK = 'Cook Islands',
    CL = 'Chile',
    CM = 'Cameroon',
    CN = 'China',
    CO = 'Colombia',
    CR = 'Costa Rica',
    CU = 'Cuba',
    CV = 'Cape Verde',
    CW = 'Curacao',
    CX = 'Christmas Island',
    CY = 'Cyprus',
    CZ = 'Czech Republic',
    DE = 'Germany',
    DJ = 'Djibouti',
    DK = 'Denmark',
    DM = 'Dominica',
    DO = 'Dominican Republic',
    DZ = 'Algeria',
    EC = 'Ecuador',
    EE = 'Estonia',
    EG = 'Egypt',
    EH = 'Western Sahara',
    ER = 'Eritrea',
    ES = 'Spain',
    ET = 'Ethiopia',
    FI = 'Finland',
    FJ = 'Fiji',
    FK = 'Falkland Islands',
    FM = 'Micronesia',
    FO = 'Faroe Islands',
    FR = 'France',
    GA = 'Gabon',
    GB = 'United Kingdom',
    GD = 'Grenada',
    GE = 'Georgia',
    GF = 'French Guiana',
    GG = 'Guernsey',
    GH = 'Ghana',
    GI = 'Gibraltar',
    GL = 'Greenland',
    GM = 'Gambia',
    GN = 'Guinea',
    GP = 'Guadeloupe',
    GQ = 'Equatorial Guinea',
    GR = 'Greece',
    GS = 'South Georgia And Sandwich Isl',
    GT = 'Guatemala',
    GU = 'Guam',
    GW = 'Guinea Bissau',
    GY = 'Guyana',
    HK = 'HongKong',
    HM = 'Heard Island and Mcdonald Islands',
    HN = 'Honduras',
    HR = 'Croatia',
    HT = 'Haiti',
    HU = 'Hungary',
    ID = 'Indonesia',
    IE = 'Ireland',
    IL = 'Israel',
    IM = 'Isle Of Man',
    IN = 'India',
    IO = 'British Indian Ocean Territory',
    IQ = 'Iraq',
    IR = 'Iran',
    IS = 'Iceland',
    IT = 'Italy',
    JE = 'Jersey',
    JM = 'Jamaica',
    JO = 'Jordan',
    JP = 'Japan',
    KE = 'Kenya',
    KG = 'Kyrgyzstan',
    KH = 'Cambodia',
    KI = 'Kiribati',
    KM = 'Comoros',
    KN = 'Saint Kitts And Nevis',
    KP = 'Korea Democratic Peoples Republic',
    KR = 'Korea',
    KW = 'Kuwait',
    KY = 'Cayman Islands',
    KZ = 'Kazakhstan',
    LA = "Lao People's Democratic Republic",
    LB = 'Lebanon',
    LC = 'SaintLucia',
    LI = 'Liechtenstein',
    LK = 'SriLanka',
    LR = 'Liberia',
    LS = 'Lesotho',
    LT = 'Lithuania',
    LU = 'Luxembourg',
    LV = 'Latvia',
    LY = 'Libyan Arab Jamahiriya',
    MA = 'Morocco',
    MC = 'Monaco',
    MD = 'Moldova',
    ME = 'Montenegro',
    MF = 'Saint Martin',
    MG = 'Madagascar',
    MH = 'Marshall Islands',
    MK = 'Macedonia',
    ML = 'Mali',
    MM = 'Myanmar',
    MN = 'Mongolia',
    MO = 'Macao',
    MP = 'Northern Mariana Islands',
    MQ = 'Martinique',
    MR = 'Mauritania',
    MS = 'Montserrat',
    MT = 'Malta',
    MU = 'Mauritius',
    MV = 'Maldives',
    MW = 'Malawi',
    MX = 'Mexico',
    MY = 'Malaysia',
    MZ = 'Mozambique',
    NA = 'Namibia',
    NC = 'New Caledonia',
    NE = 'Niger',
    NF = 'Norfolk Island',
    NG = 'Nigeria',
    NI = 'Nicaragua',
    NL = 'Netherlands',
    NO = 'Norway',
    NP = 'Nepal',
    NR = 'Nauru',
    NU = 'Niue',
    NZ = 'New Zealand',
    OM = 'Oman',
    PA = 'Panama',
    PE = 'Peru',
    PF = 'French Polynesia',
    PG = 'Papua New Guinea',
    PH = 'Philippines',
    PK = 'Pakistan',
    PL = 'Poland',
    PM = 'Saint Pierre and Miquelon',
    PN = 'Pitcairn',
    PR = 'PuertoRico',
    PS = 'Palestinian Territory',
    PT = 'Portugal',
    PW = 'Palau',
    PY = 'Paraguay',
    QA = 'Qatar',
    RE = 'Reunion',
    RO = 'Romania',
    RS = 'Serbia',
    RU = 'Russian Federation',
    RW = 'Rwanda',
    SA = 'Saudi Arabia',
    SB = 'Solomon Islands',
    SC = 'Seychelles',
    SD = 'Sudan',
    SE = 'Sweden',
    SG = 'Singapore',
    SH = 'SaintHelena',
    SI = 'Slovenia',
    SJ = 'Svalbard And JanMayen',
    SK = 'Slovakia',
    SL = 'Sierra Leone',
    SM = 'San Marino',
    SN = 'Senegal',
    SO = 'Somalia',
    SR = 'Suriname',
    SS = 'SouthSudan',
    ST = 'Sao Tome And Principe',
    SV = 'El Salvador',
    SX = 'Sint Maarten',
    SY = 'Syrian Arab Republic',
    SZ = 'Swaziland',
    TC = 'Turks And Caicos Islands',
    TD = 'Chad',
    TF = 'French Southern Territories',
    TG = 'Togo',
    TH = 'Thailand',
    TJ = 'Tajikistan',
    TK = 'Tokelau',
    TL = 'TimorLeste',
    TM = 'Turkmenistan',
    TN = 'Tunisia',
    TO = 'Tonga',
    TR = 'Turkey',
    TT = 'Trinidad And Tobago',
    TV = 'Tuvalu',
    TW = 'Taiwan',
    TZ = 'Tanzania',
    UA = 'Ukraine',
    UG = 'Uganda',
    UM = 'United States Outlying Islands',
    US = 'United States',
    UY = 'Uruguay',
    UZ = 'Uzbekistan',
    VA = 'Holy See Vatican City State',
    VC = 'Saint Vincent And Grenadines',
    VE = 'Venezuela',
    VG = 'Virgin Islands British',
    VI = 'Virgin Is lands US',
    VN = 'Vietnam',
    VU = 'Vanuatu',
    WF = 'Wallis And Futuna',
    WS = 'Samoa',
    YE = 'Yemen',
    YT = 'Mayotte',
    ZA = 'South Africa',
    ZM = 'Zambia',
    ZW = 'Zimbabwe',
}

export type CountryCode = keyof typeof COUNTRIES;
export type CountryName = `${COUNTRIES}`;

export enum CANADIAN_PROVINCES {
    AB = 'Alberta',
    BC = 'British Columbia',
    MB = 'Manitoba',
    NB = 'New Brunswick',
    NL = 'Newfoundland and Labrador',
    NT = 'Northwest Territories',
    NS = 'Nova Scotia',
    NU = 'Nunavut',
    ON = 'Ontario',
    PE = 'Prince Edward Island',
    QC = 'Québec',
    SK = 'Saskatchewan',
    YT = 'Yukon',
}

export enum US_STATES {
    AL = 'Alabama',
    AK = 'Alaska',
    AS = 'American Samoa',
    AZ = 'Arizona',
    AR = 'Arkansas',
    CA = 'California',
    CO = 'Colorado',
    CT = 'Connecticut',
    DE = 'Delaware',
    DC = 'District Of Columbia',
    FM = 'Federated States Of Micronesia',
    FL = 'Florida',
    GA = 'Georgia',
    GU = 'Guam',
    HI = 'Hawaii',
    ID = 'Idaho',
    IL = 'Illinois',
    IN = 'Indiana',
    IA = 'Iowa',
    KS = 'Kansas',
    KY = 'Kentucky',
    LA = 'Louisiana',
    ME = 'Maine',
    MH = 'Marshall Islands',
    MD = 'Maryland',
    MA = 'Massachusetts',
    MI = 'Michigan',
    MN = 'Minnesota',
    MS = 'Mississippi',
    MO = 'Missouri',
    MT = 'Montana',
    NE = 'Nebraska',
    NV = 'Nevada',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    NM = 'New Mexico',
    NY = 'New York',
    NC = 'North Carolina',
    ND = 'North Dakota',
    MP = 'Northern Mariana Islands',
    OH = 'Ohio',
    OK = 'Oklahoma',
    OR = 'Oregon',
    PW = 'Palau',
    PA = 'Pennsylvania',
    PR = 'Puerto Rico',
    RI = 'Rhode Island',
    SC = 'South Carolina',
    SD = 'South Dakota',
    TN = 'Tennessee',
    TX = 'Texas',
    UT = 'Utah',
    VT = 'Vermont',
    VI = 'Virgin Islands',
    VA = 'Virginia',
    WA = 'Washington',
    WV = 'West Virginia',
    WI = 'Wisconsin',
    WY = 'Wyoming',
}
