import classnames from 'classnames/bind';
import { MouseEventHandler } from 'react';

import classes from './Nav.module.scss';

const cx = classnames.bind(classes);

interface ToggleProps {
    isOpen: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function Toggle({ isOpen, onClick }: ToggleProps) {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();

        onClick(e);
    };

    return (
        <button
            className={cx('toggle', {
                'toggle--open': isOpen,
            })}
            onClick={handleClick}
        >
            <div className={cx('toggle__layer', 'toggle__layer-one')} />
            <div className={cx('toggle__layer', 'toggle__layer-two')} />
        </button>
    );
}
