import { getCurrentScope } from '@sentry/react';
import { useCallback } from 'react';

/**
 * This is an attempt to make the custom eventProps structureable. GTM doesn't
 * really care what data we send it but it does need to be configured properly
 * to accept the event data and send it on to GA. This is an attempt to add
 * a bit of burden to adding event props so that it might trigger for the
 * developer that some configuration needs to happen 1) in the below
 * type and 2) in GTM.
 */
interface CustomEventProps {
    // client: CLIENTS; // @depricated - use redirectUrl instead
    home: string;
    redirectUrl: string;
    related_home: string;
}

export default function useAnalytics() {
    const trackEvent = useCallback((event: string, data?: Partial<CustomEventProps>) => {
        // TODO: Track with Hubspot

        // GA
        window.dataLayer.push({
            event,
            eventProps: {
                ...data,
            },
        });
    }, []);

    const forgetUser = useCallback(() => {
        const scope = getCurrentScope();
        scope.setUser(null);
    }, []);

    return { trackEvent, forgetUser };
}
