import { IconProps } from './types';

export default function TerraceIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <circle
                cx="15"
                cy="8.25"
                fill="none"
                r="3"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M15 2.25V.75M19.243 4.007l1.06-1.06M21 8.25h1.5M19.243 12.493l1.06 1.06M15 14.25v1.5M10.757 12.493l-1.06 1.06M9 8.25H7.5M10.757 4.007l-1.06-1.06M.75 19.5S3 21 3 23.25M5.25 19.5S3 21 3 23.25M9.75 19.5S12 21 12 23.25M14.25 19.5S12 21 12 23.25M18.75 19.5S21 21 21 23.25M23.25 19.5S21 21 21 23.25M23.25 23.25H.75"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
