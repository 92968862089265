/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MouseEvent, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { useDisableScroll } from '../../hooks';
import { CloseIcon } from '../../icons';
import IconButton from '../IconButton';
import classes from './Drawer.module.scss';

export interface DrawerProps {
    /** A valid ReactNode */
    children?: ReactNode;
    /** A `ReactNode` to render a sticky footer */
    footer?: ReactNode;
    /** Callback that's called when the close button or backdrop is clicked */
    onClose: () => void;
    /** Wether the drawer is visible or not */
    showDrawer?: boolean;
    /** Text used for the drawer title */
    title: string;
    /** Width in px of the drawer */
    width?: number;
}

export default function Drawer({
    children,
    footer,
    onClose,
    showDrawer,
    title,
    width = 480,
}: DrawerProps) {
    const [blockScroll, allowScroll] = useDisableScroll();

    // Prevent drawer closing if clicking inside
    const handleContentClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const handleClose = (e: MouseEvent) => {
        e.stopPropagation();
        onClose();
    };

    useEffect(() => {
        if (showDrawer) {
            blockScroll();
        }

        return allowScroll;
    }, [allowScroll, blockScroll, showDrawer]);

    if (!showDrawer) {
        return null;
    }

    return createPortal(
        <div
            className={classes.wrapper}
            data-testid="drawer-wrapper"
            onClick={handleClose}
            role="button"
        >
            <div className={classes.drawer} onClick={handleContentClick} style={{ width }}>
                <div className={classes.header}>
                    <div className={classes.header__title}>{title}</div>
                    <IconButton
                        aria-label="Close Drawer"
                        className={classes.header__close}
                        icon={<CloseIcon />}
                        onClick={handleClose}
                        theme={null}
                    />
                </div>

                <div className={classes.body}>{children}</div>

                {footer ? <div className={classes.footer}>{footer}</div> : null}
            </div>
        </div>,
        document.body
    );
}
