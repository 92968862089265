export interface TabProps {
    /** Used to label/associate tab panels with a nav item */
    'aria-labelledby'?: string;
    /** A valid ReactNode */
    children: JSX.Element;
    /** CSS classes to add to tab button, NOT the body */
    className?: string | ((params: { isActive: boolean }) => string);
    /** Id to link the label and tab panel for screen readers */
    id?: string;
    /** Label displayed in tab navigation */
    label: string;
    /** Custom mouse over prop for the tab button used or onMouseOver and onFocus, NOT the body */
    onMouseOver?: () => void;
    /** Id used to determine the active tab */
    tabId: string;
}

export default function Tab({ 'aria-labelledby': ariaLabel, children, id }: TabProps) {
    return (
        <div aria-labelledby={ariaLabel} id={id} role="tabpanel" tabIndex={0}>
            {children}
        </div>
    );
}
