import { Outlet } from 'react-router-dom';

import { AuthModal } from '../../Auth';
import { Navigation } from '../../Navigation';
import classes from './PageLayout.module.scss';

export default function PageLayout() {
    return (
        <>
            <div className={classes.wrapper}>
                <Navigation />
                <Outlet />
            </div>
            <AuthModal />
        </>
    );
}
