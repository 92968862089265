import { IconProps } from './types';

export default function HeartOutlineIcon({ height = 23, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 23.7 22.5"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M11.85 22.5c-.18 0-.35-.06-.49-.18C9 20.28-2.58 9.77.53 3.55A6.384 6.384 0 0 1 4.24.33a6.37 6.37 0 0 1 4.9.35c.62.31 1.18.71 1.67 1.2l1.04 1.04 1.04-1.04c2.5-2.5 6.58-2.5 9.08 0 .49.49.89 1.05 1.2 1.67 3.23 6.46-9.38 17.53-10.83 18.77-.14.12-.31.18-.49.18Zm-5.57-21c-.52 0-1.05.08-1.56.26a4.899 4.899 0 0 0-2.85 2.47c-2.1 4.19 5.07 12.15 9.98 16.53 4.65-4.1 12.11-12.26 9.97-16.53-.24-.47-.55-.9-.92-1.28a4.925 4.925 0 0 0-6.96 0l-1.57 1.57c-.29.29-.77.29-1.06 0L9.74 2.96c-.38-.38-.81-.69-1.28-.92-.69-.35-1.44-.52-2.2-.52Z"
                fill="currentColor"
            />
        </svg>
    );
}
