import { Helmet } from 'react-helmet-async';

import Whoops from '../components/Whoops';

export default function NoMatch() {
    return (
        <>
            <Helmet>
                <title>404 | Key</title>
            </Helmet>
            <Whoops message="Whoops, something went wrong." status="404" />
        </>
    );
}
