import { IconProps } from './types';

export default function HeatingIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M21.978 14.934A3.7 3.7 0 0 1 23 17.382c0 3.241-5.037 5.87-11.25 5.87S.5 20.623.5 17.382a3.7 3.7 0 0 1 1.021-2.446M6.95 2.219A4.8 4.8 0 0 0 5.3 5.375c0 1.972 1.65 3.944 1.65 5.917a4.8 4.8 0 0 1-1.65 3.155M18.2 2.219a4.8 4.8 0 0 0-1.65 3.156c0 1.972 1.65 3.945 1.65 5.917a4.8 4.8 0 0 1-1.65 3.156"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M12.773.752a5.946 5.946 0 0 0-2.046 3.912c0 2.447 2.046 4.892 2.046 7.337a5.95 5.95 0 0 1-2.046 3.914"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
