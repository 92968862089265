import { IconProps } from './types';

export default function ParkingIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <circle
                cx="12"
                cy="12"
                fill="none"
                r="11.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M15 10.5a2.25 2.25 0 0 1-2.25 2.25H10.5v-4.5h2.25A2.25 2.25 0 0 1 15 10.5ZM10.5 17.25v-4.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
