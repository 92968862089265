import { IconProps } from './types';

export default function CheckmarkInCircle({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M6,13.223,8.45,16.7a1.049,1.049,0,0,0,1.707.051L18,6.828"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <circle
                cx="12"
                cy="11.999"
                fill="none"
                r="11.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
        </svg>
    );
}
