import { ErrorMessage, useGlobalAuth } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../../hooks/redux';
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import useEnv from '../../../hooks/useEnv';
import { setAuthDetails, useLazyMeQuery, useSigninMutation } from '../../../redux/modules/auth';
import classes from '../Auth.module.scss';
import LoginForm, { Inputs } from './LoginForm';

const cx = classNames.bind(classes);

export default function Login() {
    const [, setSearchParams] = useSearchParams();
    const [hasError, setHasError] = useState<boolean>(false);
    const [signIn, { isLoading: loginLoading }] = useSigninMutation();
    const { setAuthData } = useAuth();
    const { trackEvent } = useAnalytics();
    const { setToken: setAuthToken } = useGlobalAuth();
    const [getCurrentUserDetails, { isLoading: currentUserLoading }] = useLazyMeQuery();
    const env = useEnv();
    const dispatch = useAppDispatch();
    const isLoading = loginLoading || currentUserLoading;

    const onSubmit: SubmitHandler<Inputs> = async ({ email, password }) => {
        try {
            const data = await signIn({ email, password }).unwrap();
            const { claims, token } = data;

            /**
             * Setting the auth cookie here isn't ideal as it should all be handled in one
             * place, the Accounts client. Couldn't find a way to integrate the Accounts
             * client for the HLP login so for an MVP just duplicating login logic here...
             *
             * TODO: Integrate the Accounts client for login
             */

            setAuthToken(token, {
                expires: new Date(claims.exp * 1_000), // num milliseconds
            });

            /**
             * Needed for the classifications api call but we dont want to set all the auth data
             * and show a logged in state until we know we are a freemium user.
             */
            dispatch(setAuthDetails({ claims: null, token, user: null }));

            // Get the classifications
            const { classifications } = await getCurrentUserDetails().unwrap();

            /**
             * The order of the checks below is important. A resident will also be an
             * applicant. We want to log them into the resident portal first.
             */

            // Redirect resident to resident portal
            if (classifications.resident) {
                const redirectUrl = env.REACT_APP_RESIDENT_PORTAL_LOGIN_URL;
                const url = new URL(redirectUrl);

                trackEvent('login', {
                    redirectUrl,
                });

                window.location.href = url.toString();

                return;
            }

            // If we are a freemium user, just set auth data in state and close the login form
            if (classifications.freemium) {
                // Set state
                await setAuthData({ data });

                trackEvent('login', {
                    redirectUrl: window.location.href,
                });

                // Close modal
                setSearchParams((params) => {
                    params.delete('authForm');
                    return params;
                });

                return;
            }

            // Redirect applicant to application form
            if (classifications.applicant) {
                const redirectUrl = env.REACT_APP_APPLICATION_FORM_LOGIN_URL;
                const url = new URL(redirectUrl);

                trackEvent('login', {
                    redirectUrl,
                });

                window.location.href = url.toString();

                return;
            }

            // Redirect owner to Owner Portal
            if (classifications.owner) {
                const redirectUrl = env.REACT_APP_OWNER_PORTAL_LOGIN_URL;
                const url = new URL(redirectUrl);

                trackEvent('login', {
                    redirectUrl,
                });

                window.location.href = url.toString();

                return;
            }

            // Fallback to login to HLP
            await setAuthData({ data });

            trackEvent('login', {
                redirectUrl: window.location.href,
            });

            // Close modal
            setSearchParams((params) => {
                params.delete('authForm');
                return params;
            });
        } catch (error) {
            setHasError(true);
        }
    };

    useEffect(() => {
        trackEvent('login_view');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="content">
                <h1 className={cx('title')}>Login</h1>
            </div>
            <LoginForm isLoading={isLoading} onSubmit={onSubmit}>
                <>
                    {hasError && (
                        <ErrorMessage message="Email or Password is incorrect" name="form-error" />
                    )}

                    <div className={classes['form-switcher']}>
                        Don&rsquo;t have an account?{' '}
                        <button
                            className={classes['secondary-action']}
                            onClick={() => {
                                setSearchParams((params) => {
                                    params.set('authForm', 'register');
                                    return params;
                                });
                            }}
                            type="button"
                        >
                            Sign Up
                        </button>
                    </div>
                </>
            </LoginForm>
        </>
    );
}
