import { kebabCase } from '@keyliving/utils';
import classNames from 'classnames/bind';
import { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as ProfileIcon } from '../../../assets/icons/ProfileIcon.svg';
import useAuth from '../../../hooks/useAuth';
import { accountMenuItems, accountMenuItemsDefault } from '../constants';
import classes from './Account.module.scss';

const cx = classNames.bind(classes);

interface AccountMenuProps {
    // Silly, but needed to close the menu on navigate
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export default function AccountMenu({ onClick }: AccountMenuProps) {
    const { isLoggedIn } = useAuth();

    const menuItems = isLoggedIn ? accountMenuItems : accountMenuItemsDefault;

    return (
        <>
            <div className={classes['account-menu']}>
                <div className={classes['account-menu__heading']}>
                    <div className={classes['account-menu__heading-icon']}>
                        <ProfileIcon aria-hidden height={30} width={30} />
                    </div>
                    My Account
                </div>
                {menuItems.map(({ label, url }, index) => {
                    return (
                        <NavLink
                            className={({ isActive }) => {
                                return cx(classes.item, {
                                    'item--active': isActive,
                                });
                            }}
                            key={`${index}-${kebabCase(url)}`}
                            onClick={onClick}
                            to={url}
                        >
                            {label}
                        </NavLink>
                    );
                })}
            </div>
        </>
    );
}
