import { LoadingRing } from '@keyliving/component-lib';

import classes from './LoadingScreen.module.scss';

export default function LoadingScreen() {
    return (
        <div className={classes.wrapper}>
            <LoadingRing />
        </div>
    );
}
