import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PageLayout from '../components/layout/PageLayout';
import LoadingScreen from '../components/LoadingScreen';
import { PrivateRoute } from '../components/PrivateRoute';
import EditProfile from '../pages/auth/EditProfile/EditProfile';
import NoMatch from '../pages/NoMatch';
import URLS from './urls';

const Asset = lazy(() => import('../pages/Asset'));
const Building = lazy(() => import('../pages/Building'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const ListingDetails = lazy(() => import('../pages/ListingDetails'));
const Impersonate = lazy(() => import('../pages/auth/Impersonate'));
const Logout = lazy(() => import('../pages/auth/Logout'));
const NewPassword = lazy(() => import('../pages/auth/NewPassword'));
const SearchListing = lazy(() => import('../pages/SearchListing'));

const redirects: { from: string; to: string }[] = [
    {
        from: '/homes/toronto/45-william-street/main',
        to: '/homes/toronto/45-william-street',
    },
    {
        from: '/homes/texas/old-troup-hwy/3801',
        to: '/homes/texas/3801-old-troup-hwy',
    },
];

const publicRoutes: RouteObject[] = [
    ...redirects.map(({ from, to }) => {
        return {
            path: from,
            element: <Navigate replace={true} to={to} />,
        };
    }),
    {
        path: '/',
        element: (
            // Temp Redirect. we should build out a page here. Maybe were the user has to pick a city?
            <Navigate
                to={URLS.Homes.City.buildPath({
                    city: 'toronto',
                })}
            />
        ),
        index: true,
    },
    {
        path: URLS.Homes.path,
        children: [
            {
                index: true,
                element: (
                    // Temp Redirect. we should build out a page here. Maybe list all homes?
                    <Navigate
                        to={URLS.Homes.City.buildPath({
                            city: 'toronto',
                        })}
                    />
                ),
            },
            {
                path: URLS.Homes.City.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<LoadingScreen />}>
                                <SearchListing />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Homes.City.Building.path,
                        children: [
                            {
                                index: true,
                                element: (
                                    <Suspense fallback={<LoadingScreen />}>
                                        <Building />
                                    </Suspense>
                                ),
                            },
                            {
                                path: URLS.Homes.City.Building.Asset.path,
                                element: (
                                    <Suspense fallback={<LoadingScreen />}>
                                        <Asset />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: URLS.ListingDetails.path,
        element: (
            // This is a Legacy page we need to keep for now so we can redirect users to the new urls
            <Suspense fallback={<LoadingScreen />}>
                <ListingDetails />
            </Suspense>
        ),
    },
    {
        path: URLS.Logout.path,
        element: (
            <Suspense fallback={<LoadingScreen />}>
                <Logout />
            </Suspense>
        ),
    },
    {
        path: URLS.NewPassword.path,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<LoadingScreen />}>
                        <NewPassword />
                    </Suspense>
                ),
            },
        ],
    },
    {
        /**
         * This path is intentionally not part of the ULRS object. It's a "secret" route
         * just for impersonating users. It's not meant to be navigated to. The "login"
         * functionality should be handled by the authModal component.
         */
        path: 'impersonate',
        element: (
            <Suspense fallback={<LoadingScreen />}>
                <Impersonate />
            </Suspense>
        ),
    },
    {
        path: '*',
        element: (
            <Suspense fallback={<LoadingScreen />}>
                <NoMatch />
            </Suspense>
        ),
    },
];

const privateRoutes: RouteObject[] = [
    {
        path: URLS.Account.path,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<LoadingScreen />}>
                        <Dashboard />
                    </Suspense>
                ),
            },
            {
                path: URLS.Account.EditProfile.path,
                element: (
                    <Suspense fallback={<LoadingScreen />}>
                        <EditProfile />
                    </Suspense>
                ),
            },
        ],
    },
];

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <PageLayout />,
        children: publicRoutes,
    },
    {
        path: URLS.Account.path,
        element: <PrivateRoute />,
        children: privateRoutes,
    },
];

export default routes;
