import { isValidPassword, stringOrNull } from '@keyliving/utils';
import { object, preprocess, string, ZodIssueCode } from 'zod';

import { REQUIRED_MSG } from '../../../constants';

export const registerValidation = object({
    name: string().min(1, { message: REQUIRED_MSG }).max(75),
    phone_number: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        })
            .min(1, { message: 'Phone number is required' })
            .regex(/^\d+$/, { message: 'Phone number must only contain numbers' })
            .length(10, { message: 'Phone number must be 10 digits long' })
            .nullable()
    ),
    email: string()
        .min(1, { message: REQUIRED_MSG })
        .max(75)
        .email({ message: 'Invalid email address' }),
    password: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        })
            .min(8, { message: 'Password must be at least 8 characters' })
            .max(100)
    ),
}).superRefine(({ password }, ctx) => {
    const { errors, hasErrors } = isValidPassword(password);

    if (hasErrors) {
        errors.forEach((msg) => {
            ctx.addIssue({
                code: ZodIssueCode.custom,
                message: msg,
                path: ['password'],
            });
        });
    }
});
