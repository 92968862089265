import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';

import { ReactComponent as ProfileIcon } from '../../../assets/icons/ProfileIcon.svg';
import useAuth from '../../../hooks/useAuth';
import { accountMenuItems, accountMenuItemsDefault } from '../constants';
import Dropdown from '../Dropdown';
import classes from './Account.module.scss';

const cx = classNames.bind(classes);

function DropdownLabel() {
    return (
        <>
            <div className={classes['account-menu__heading-icon']}>
                <ProfileIcon aria-hidden height={31} width={31} />
            </div>
        </>
    );
}

export default function AccountDropdown() {
    const { isLoggedIn } = useAuth();

    const menuItems = isLoggedIn ? accountMenuItems : accountMenuItemsDefault;

    return (
        <Dropdown
            className={classes['account-dropdown']}
            id="account-menu"
            items={menuItems}
            label={<DropdownLabel />}
            renderItem={({ activeClassName, className, item: { label, url }, key, role }) => (
                <NavLink
                    className={({ isActive }) => {
                        return cx(className, {
                            [`${activeClassName}`]: isActive,
                        });
                    }}
                    key={key}
                    role={role}
                    to={url}
                >
                    {label}
                </NavLink>
            )}
        />
    );
}
