import { User } from '@keyliving/shared-types';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getCurrentScope } from '@sentry/react';

import { setAuthDetails, setCurrentUser, updateUserDetails } from './authModule';

const _hsq = (window._hsq = window._hsq || []);

export const authListenerMiddleware = createListenerMiddleware();

function identifyUser(action: { payload: User; type: string }) {
    /**
     * When we set a user, identify that user across our
     * various tracking services.
     */
    const { email, id, org_codes } = action.payload;

    let userOrg: string | null = null;

    if (org_codes && org_codes.length) {
        const [firstOrg] = org_codes;
        userOrg = firstOrg;
    }

    /**
     * Sentry - setup a scope for Sentry that will
     * add details to captured exceptions for that user.
     */
    const scope = getCurrentScope();
    scope.setUser({ id });

    if (userOrg) {
        scope.setTag('org', userOrg);
    }

    // Google Analytics
    window.dataLayer.push({
        user_id: id,
        org: userOrg,
    });

    /**
     * Hubspot
     *
     * NOTE: We identify hubspot with email vs user.id with other services.
     * Not sure if this will cause issues with cross referencing users but
     * keeping as is for now.
     */
    _hsq.push([
        'identify',
        {
            email,
        },
    ]);
}

authListenerMiddleware.startListening({
    actionCreator: setCurrentUser,
    effect: identifyUser,
});

authListenerMiddleware.startListening({
    actionCreator: setAuthDetails,
    effect: ({ payload, type }) => {
        const { user } = payload;

        if (user) {
            identifyUser({
                payload: user,
                type,
            });
        }
    },
});

// Only neede for Hubspot as its the only one that uses email to identify
authListenerMiddleware.startListening({
    actionCreator: updateUserDetails,
    effect: ({ payload }) => {
        const { email } = payload;

        _hsq.push([
            'identify',
            {
                email,
            },
        ]);
    },
});
