import PageContainer from '../../../components/PageContainer/PageContainer';
import useAuth from '../../../hooks/useAuth';
import classes from './EditProfile.module.scss';
import EditProfileForm from './EditProfileForm';

export default function EditProfile() {
    const { user } = useAuth();

    if (!user) {
        return (
            <PageContainer>
                <div className={classes['narrow-content']}>
                    <div className="content">
                        <p>Whoops, we cant find your user information</p>
                    </div>
                </div>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <div className={classes['narrow-content']}>
                <div className={classes['form-header']}>
                    <h1 className={classes.title}>Edit Profile</h1>
                    <div className={classes.description}>
                        <p>We get it, emails change. Use the form below to update your details.</p>
                    </div>
                </div>

                <EditProfileForm user={user} />
            </div>
        </PageContainer>
    );
}
