import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ErrorMessage, FormGroup, TextInput, useToast } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { object, output, string } from 'zod';

import { SUPPORT_EMAIL } from '../../../constants';
import { useForgotPasswordMutation } from '../../../redux/modules/auth';
import classes from '../Auth.module.scss';

const cx = classNames.bind(classes);

const validation = object({
    email: string()
        .min(1, { message: 'Email is required' })
        .email({ message: 'Invalid email address' }),
});

type Inputs = output<typeof validation>;

export default function ForgotPassword() {
    const [, setSearchParams] = useSearchParams();
    const [forgotPassword, { isError, isLoading }] = useForgotPasswordMutation();
    const toast = useToast();

    const {
        formState: { errors: validationErrors },
        handleSubmit,
        register,
    } = useForm<Inputs>({
        resolver: zodResolver(validation),
    });

    const onSubmit: SubmitHandler<Inputs> = async ({ email }) => {
        const res = await forgotPassword(email);

        // We only have data if is successful
        if ('data' in res) {
            toast.success('Email sent. Please check your email for reset instructions.');
            setSearchParams((params) => {
                params.delete('authForm');
                return params;
            });
        }
    };

    return (
        <div>
            <div>
                <div className="content">
                    <h1 className={cx('title')}>Forgot Password</h1>
                    <p>
                        Enter the email associated with your account to reset your password. It can
                        take a few minutes to receive the link.
                    </p>
                </div>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                        autoCapitalize="none"
                        autoCorrect="off"
                        errors={validationErrors}
                        inputMode="email"
                        label="Email"
                        placeholder="Email Address"
                        {...register('email')}
                    />

                    {isError && (
                        <FormGroup>
                            <ErrorMessage
                                message={`Could not reset password. Please contact ${SUPPORT_EMAIL} for help.`}
                                name="form-error"
                            />
                        </FormGroup>
                    )}

                    <div className={classes.submit}>
                        <Button
                            isLoading={isLoading}
                            rounded
                            size="md"
                            theme="tertiary"
                            type="submit"
                            width="full"
                        >
                            Submit
                        </Button>
                    </div>
                </form>

                <div className={classes['form-switcher']}>
                    Remember your password?{' '}
                    <button
                        className={classes['secondary-action']}
                        onClick={() => {
                            setSearchParams((params) => {
                                params.set('authForm', 'login');
                                return params;
                            });
                        }}
                        type="button"
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
}
