import { IconProps } from './types';

export default function WaterIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M17.251 12a5.25 5.25 0 0 1-10.5 0c0-4.5 4.5-9.75 5.25-9.75s5.25 5.25 5.25 9.75Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M21.547 15.574a2.494 2.494 0 0 1 .954 1.817c0 2.408-4.7 4.359-10.5 4.359S1.5 19.8 1.5 17.391a2.49 2.49 0 0 1 .954-1.817M12 14.25A2.25 2.25 0 0 1 9.751 12"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
