import { getCurrentRootDomain } from '@keyliving/utils';
import { useCallback } from 'react';

import useCookie from './useCookie';

const AUTH_COOKIE_NAME = 'KeyAuth';

/**
 * Get the cookie name and auth url to use for the current environment based on the hostname.
 *
 * TODO: HATE that the authClientUrl's are hardcoded here.
 *
 * @returns The cookie name and auth url for the current environment.
 */
function getEnvironmentVariables() {
    const hostname = window.location.hostname;

    if (hostname.includes('localhost')) {
        return {
            cookieName: `dev${AUTH_COOKIE_NAME}`,
            authClientUrl: 'http://localhost:3006',
        };
    }

    if (hostname.includes('demo')) {
        return {
            cookieName: `demo${AUTH_COOKIE_NAME}`,
            authClientUrl: 'https://accounts-demo.keyown.com',
        };
    }

    if (hostname.includes('staging')) {
        return {
            cookieName: `staging${AUTH_COOKIE_NAME}`,
            authClientUrl: 'https://accounts-staging.keyown.com',
        };
    }

    return {
        cookieName: `prod${AUTH_COOKIE_NAME}`,
        authClientUrl: 'https://accounts.keyown.com',
    };
}

type SearchParams = Record<string, string> & {
    redirectUrl?: string;
};

/**
 * TODO: How to make the relativeAuthRoute type safe? Maybe a way to export
 * the URLS object from the Accounts client to "buildPath()"? A way to
 * generate a type union?
 */

type RelativeAuthRoute = '/login' | '/magic-link';

interface RedirectToAuthParams {
    /** relative url path of the Account client you would like to send the user. Default is /login */
    relativeAuthRoute?: RelativeAuthRoute;
    /** Search params added to the auth client url. e.g. redirectUrl */
    searchParams?: SearchParams;
}

/**
 * Convenient hook to interact with auth.
 */
export default function useGlobalAuth() {
    const { authClientUrl, cookieName } = getEnvironmentVariables();
    const { deleteCookie, setCookie, value, ...rest } = useCookie(cookieName, {
        domain: getCurrentRootDomain(),
    });

    /**
     * Redirect to the auth client to authenticate. Will use the current url
     * to redirect back to if none provided.
     */
    const redirectToAuth = useCallback(
        ({ relativeAuthRoute, searchParams }: RedirectToAuthParams) => {
            const authRoute = relativeAuthRoute ?? '/login';
            const url = new URL(authRoute, authClientUrl);

            if (searchParams) {
                Object.entries(searchParams).forEach(([key, value]) => {
                    url.searchParams.set(key, value);
                });
            }

            // If they haven't supplied a return url, add one
            if (!url.searchParams.get('redirectUrl')) {
                url.searchParams.set('redirectUrl', window.location.href);
            }

            window.location.href = url.toString();
        },
        [authClientUrl]
    );

    return {
        token: value,
        setToken: setCookie,
        deleteToken: deleteCookie,
        redirectToAuth,
        ...rest,
    };
}
