import {
    arrow,
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    shift,
    Side,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { cloneElement, useRef, useState } from 'react';

import classes from './Tooltip.module.scss';

/** Arrow needs to be on the opposite side of the tooltip placement */
const arrowPositionMap: Record<Side, Side> = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
};

export interface TooltipProps {
    /** A valid JSX.Element */
    children: JSX.Element;
    /** Is the tooltip visible initially */
    isOpen?: boolean;
    /** Label text for the tooltip */
    label: string;
    /** The initial position of the tooltip if there is enough screen real estate */
    placement?: Side;
}

export default function Tooltip({ children, isOpen, label, placement = 'top' }: TooltipProps) {
    const arrowRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(isOpen);

    const {
        context,
        middlewareData,
        placement: computedSide,
        refs,
        strategy,
        x,
        y,
    } = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [offset(12), flip(), shift({ padding: 8 }), arrow({ element: arrowRef })],
        whileElementsMounted: autoUpdate,
    });

    const { getFloatingProps, getReferenceProps } = useInteractions([
        useHover(context, {
            delay: {
                open: 500,
                close: 250,
            },
        }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);

    const { arrow: { x: arrowX, y: arrowY } = {} } = middlewareData;

    /** Get the side the arrow should be on accounting for the flip() middleware  */
    const arrowPosition = arrowPositionMap[computedSide as Side];

    return (
        <>
            {cloneElement(
                children,
                getReferenceProps({ ref: refs.setReference, ...children.props })
            )}
            <FloatingPortal>
                {open && (
                    <div
                        {...getFloatingProps({
                            ref: refs.setFloating,
                            className: classes.tooltip,
                            style: {
                                position: strategy,
                                top: y ?? '',
                                left: x ?? '',
                            },
                        })}
                    >
                        {label}
                        <div
                            className={classes.tooltip__arrow}
                            ref={arrowRef}
                            style={{
                                top: arrowY ?? '',
                                left: arrowX ?? '',
                                [arrowPosition]: '-4px',
                            }}
                        />
                    </div>
                )}
            </FloatingPortal>
        </>
    );
}
