import { Outlet } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { AuthLayout } from '../layout/AuthLayout';
import PrivateRouteFallback from './PrivateRouteFallback';

export default function PrivateRoute() {
    const { isLoggedIn } = useAuth();

    return <AuthLayout>{isLoggedIn ? <Outlet /> : <PrivateRouteFallback />}</AuthLayout>;
}
