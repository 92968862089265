import { DropdownItem } from './Dropdown';

export const accountMenuItems: DropdownItem[] = [
    {
        label: 'Favourites',
        url: '/account',
    },
    {
        label: 'Edit Profile',
        url: '/account/edit-profile',
    },
    {
        label: 'Log Out',
        url: '/logout',
    },
];

export const accountMenuItemsDefault: DropdownItem[] = [
    {
        label: 'Login',
        url: '/account?authForm=login',
    },
];
