import { IconProps } from './types';

export default function CloseInCircleIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <circle
                cx="12"
                cy="11.999"
                fill="none"
                r="11.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="m7.5 16.499 9-9M16.5 16.499l-9-9"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
