import { Modal } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';

import classes from './Auth.module.scss';
import { ForgotPassword, Login } from './Login';
import { Register } from './Register';

const cx = classNames.bind(classes);

const forms: Record<string, () => JSX.Element> = {
    login: Login,
    forgot: ForgotPassword,
    register: Register,
};

export default function AuthModal() {
    // TODO: after updating react-router-typesafe-routes package to v1.1.0, use useTypedSearchParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const authAction = searchParams.get('authForm');
    const Form = forms[authAction ?? 'login'];

    return (
        <Modal
            className={cx(`modal--${authAction}`)}
            id={classes['auth-modal']}
            noPadding={authAction === 'register'}
            onClose={() => {
                setSearchParams((params) => {
                    params.delete('authForm');
                    return params;
                });
            }}
            showModal={!!authAction}
        >
            <Form />
        </Modal>
    );
}
