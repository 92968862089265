import { BaseContractCondition } from './Contract';

export interface AssetDetails {
    bathrooms: number;
    bedrooms: number;
    description: string | null;
    heading: string | null;
    hydro: boolean;
    sqft: number;
    tour_url: string | null;
    water: boolean;
}

type AssetDetailsKeys = keyof AssetDetails;

export interface BaseAssetContract {
    id: string;
    building_suite_id: string;
    building_suite_contract_conditions_id: string;
    value: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    deleted_by: string | null;
    created_by: string | null;
}

export interface AssetValue {
    building_suite_id: string;
    created_at: string;
    id: string;
    is_active: boolean;
    value: number;
}

export interface AssetValueSummary {
    num_assets: number;
    total_value: number;
    month: string;
}

export enum ASSET_STATUS {
    AVAILABLE = 'AVAILABLE',
    SOLD = 'SOLD',
    OFF_MARKET = 'OFF_MARKET',
}

// This matches the structure in the DB
export interface BaseAsset extends AssetDetails {
    id: string;
    unit: string | null;
    created_at: string | null;
    updated_at: string | null;
    building_id: string;
    image_folder_id: string | null;
    published_monthly_residency_payment: number;
    published_suite_value: number;
    rank: number | null;
    is_published: boolean;
    is_managed: boolean;
    published_at: string | null;
    published_status: ASSET_STATUS | null;
    published_contractual_rent: number | null;
}

export type AssetContract = Pick<BaseAssetContract, 'value'> &
    Pick<BaseContractCondition, 'type' | 'description' | 'explanation' | 'formula'>;

export enum ASSET_OCCUPANCY_TYPE {
    VACANT = 'VACANT', // Asset is empty
    TENANT = 'TENANT', // Asset has a tenant but is not an Owner Resident in the Key program
    OWNER_RESIDENT = 'OWNER_RESIDENT', // Asset has a Resident in the Key program
}

export interface AssetFeature {
    id: string;
    type: string;
    amount: number | null;
}

export interface AssetResident {
    id: string;
    name: string | null;
    agreement_start_date: string | null;
    agreement_end_date: string | null;
 }

// This matches what comes from the GET route after DB joins/manipulation
export interface Asset extends Omit<BaseAsset, AssetDetailsKeys | 'unit' | 'building_id'> {
    id: string;
    address: {
        building_name: string;
        city: string;
        country: string;
        postal_code: string;
        region: string;
        street: string;
    } & Pick<BaseAsset, 'unit' | 'building_id'>;
    agreement_start_date: string | null;
    contracts?: AssetContract[];
    details: AssetDetails;
    features: AssetFeature[];
    photos?: string[];
    value: number | null;
    user_id: string | null;
    created_at: string;
    updated_at: string;
    resident?: AssetResident;
    owner?: {
        id: string;
        name: string;
    };
    in_equity_program: boolean | null;
}
