/**
 * Get the root domain of the given url
 *
 * @param host the window.location.hostname e.g. localhost:3000, blog.keyown.com
 */
export function transformHostToRootDomain(hostname: string) {
    return hostname.split('.').reverse().splice(0, 2).reverse().join('.');
}

/**
 * Get the root domain of the current url.
 *
 * e.g. homes.keyown.com -> keyown.com
 */
export default function getCurrentRootDomain() {
    return transformHostToRootDomain(window.location.hostname);
}
