import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormGroup, PasswordInput, TextInput } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { ReactNode } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { object, output, string } from 'zod';

import classes from '../Auth.module.scss';

const cx = classNames.bind(classes);

const validation = object({
    email: string()
        .min(1, { message: 'Email is required' })
        .email({ message: 'Invalid email address' }),
    password: string().min(1, { message: 'Password is required' }),
});

export type Inputs = output<typeof validation>;

interface LoginFormProps {
    children?: ReactNode;
    isLoading: boolean;
    onSubmit: SubmitHandler<Inputs>;
}

export default function LoginForm({ children, isLoading, onSubmit }: LoginFormProps) {
    const [, setSearchParams] = useSearchParams();

    const {
        formState: { errors: validationErrors },
        handleSubmit,
        register,
    } = useForm<Inputs>({
        resolver: zodResolver(validation),
    });

    return (
        <>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    autoCapitalize="none"
                    autoCorrect="off"
                    errors={validationErrors}
                    inputMode="email"
                    label="Email"
                    placeholder="Email Address"
                    {...register('email')}
                />
                <PasswordInput
                    errors={validationErrors}
                    label="Password"
                    placeholder="Password"
                    {...register('password')}
                />

                <FormGroup>
                    <button
                        className={classes['secondary-action']}
                        onClick={() => {
                            setSearchParams((params) => {
                                params.set('authForm', 'forgot');
                                return params;
                            });
                        }}
                        type="button"
                    >
                        Forgot Password?
                    </button>
                </FormGroup>

                <div className={cx(classes.submit)}>
                    <Button
                        isLoading={isLoading}
                        rounded
                        size="md"
                        theme="tertiary"
                        type="submit"
                        width="full"
                    >
                        Login
                    </Button>
                </div>
            </form>

            {children}
        </>
    );
}
