// Updated and accurate to prod db as of Aug 22 2024
export enum PRODUCT_TYPE {
    ADJUSTMENT_GENERAL = 'ADJUSTMENT_GENERAL',
    ADJUSTMENT_RENT = 'ADJUSTMENT_RENT',
    ADJUSTMENT_SERVICES = 'ADJUSTMENT_SERVICES',
    AUTO_EQUITY_PURCHASE = 'AUTO_EQUITY_PURCHASE',
    BETA_EQUITY_BOOST_CREDIT = 'BETA_EQUITY_BOOST_CREDIT',
    BETA_INSURANCE_DISCOUNT_CREDIT = 'BETA_INSURANCE_DISCOUNT_CREDIT',
    BETA_RENT_DEPOSIT = 'BETA_RENT_DEPOSIT',
    CARRYING_COST = 'CARRYING_COST',
    CO_FINANCING_INTEREST = 'CO_FINANCING_INTEREST',
    CONDO_FEES = 'CONDO_FEES',
    CONTRACTUAL_RENT = 'CONTRACTUAL_RENT',
    EQUITY_ADMIN_FEE = 'EQUITY_ADMIN_FEE',
    EQUITY_BOOST = 'EQUITY_BOOST',
    EQUITY_PURCHASE = 'EQUITY_PURCHASE',
    EQUITY_REDEMPTION = 'EQUITY_REDEMPTION',
    HAAS_FEE = 'HAAS_FEE',
    HOME_ONBOARDING_FEE = 'HOME_ONBOARDING_FEE',
    HYDRO = 'HYDRO',
    INITIAL_INVESTMENT = 'INITIAL_INVESTMENT',
    INSPECTION_FEE = 'INSPECTION_FEE',
    LOCKER = 'LOCKER',
    MOVING_SERVICES = 'MOVING_SERVICES',
    OCCUPANCY_PAYMENT = 'OCCUPANCY_PAYMENT',
    OWNERSHIP_SAVINGS = 'OWNERSHIP_SAVINGS',
    OWNERSHIP_SAVINGS_REINVESTMENT = 'OWNERSHIP_SAVINGS_REINVESTMENT',
    PARKING = 'PARKING',
    REFUND = 'REFUND',
    REMAINING_DEPOSITS = 'REMAINING_DEPOSITS',
    RENT = 'RENT',
    RENT_EQUIVALENT = 'RENT_EQUIVALENT',
    REPAIR_AND_MAINTENANCE = 'REPAIR_AND_MAINTENANCE',
    RESIDENT_INSURANCE = 'RESIDENT_INSURANCE',
    RESIDENT_ONBOARDING_FEE = 'RESIDENT_ONBOARDING_FEE',
    RESIDENT_SOURCING_FEE = 'RESIDENT_SOURCING_FEE',
    SALES_TAX = 'SALES_TAX',
    SUITE_EXPENSES = 'SUITE_EXPENSES',
    WATER = 'WATER',
    WEAR_AND_TEAR_CHARGE = 'WEAR_AND_TEAR_CHARGE',
}

// This matches the structure in the DB
export interface BaseProduct {
    id: string;
    type: PRODUCT_TYPE;
    description: string | null;
    created_at: string | null;
    updated_at: string | null;
    taxable: boolean;
}

// This matches what comes from the GET route after DB joins/manipulation
export interface Product extends BaseProduct {
    created_at: string;
    updated_at: string;
}
