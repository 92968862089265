import { IconProps } from './types';

export default function BuildingIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M20.25 3.75v19.5M3.75 3.75h16.5M3.75 23.25V3.75M20.25 23.25H3.75M20.25 3.75H3.75l1.5-3h13.5l1.5 3ZM.75 23.25h22.5M14.25 21a2.25 2.25 0 0 0-4.5 0v2.25h4.5V21Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M7.5 7.125a.375.375 0 1 1 0-.75M7.5 7.125a.375.375 0 1 0 0-.75M12 7.125a.375.375 0 0 1 0-.75M12 7.125a.375.375 0 0 0 0-.75M16.5 7.125a.375.375 0 0 1 0-.75M16.5 7.125a.375.375 0 0 0 0-.75M7.5 11.625a.375.375 0 0 1 0-.75M7.5 11.625a.375.375 0 0 0 0-.75M12 11.625a.375.375 0 0 1 0-.75M12 11.625a.375.375 0 0 0 0-.75"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <g stroke="currentColor" strokeWidth="1.5">
                <path d="M16.5 11.625a.375.375 0 0 1 0-.75M16.5 11.625a.375.375 0 0 0 0-.75" />
            </g>
            <g stroke="currentColor" strokeWidth="1.5">
                <path d="M7.5 16.125a.375.375 0 0 1 0-.75M7.5 16.125a.375.375 0 0 0 0-.75" />
            </g>
            <g stroke="currentColor" strokeWidth="1.5">
                <path d="M12 16.125a.375.375 0 0 1 0-.75M12 16.125a.375.375 0 0 0 0-.75" />
            </g>
            <g stroke="currentColor" strokeWidth="1.5">
                <path d="M16.5 16.125a.375.375 0 0 1 0-.75M16.5 16.125a.375.375 0 0 0 0-.75" />
            </g>
        </svg>
    );
}
