import { CheckmarkInCircle } from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { useEffect } from 'react';

import useAnalytics from '../../../hooks/useAnalytics';
import classes from './Register.module.scss';
import RegisterForm from './RegisterForm';

const cx = classNames.bind(classes);

export default function Register() {
    const { trackEvent } = useAnalytics();

    useEffect(() => {
        trackEvent('registration_view');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.wrapper}>
            <div className={cx('body')}>
                <div className={cx('content')}>
                    <h1 className={cx('title')}>
                        Unlock these features and start your journey by creating a free account
                    </h1>
                    <ul className={classes['feature-list']}>
                        <li>
                            <div>
                                <CheckmarkInCircle height={18} width={18} />
                            </div>{' '}
                            Get notified when new homes are available.
                        </li>
                        <li>
                            <div>
                                <CheckmarkInCircle height={18} width={18} />
                            </div>{' '}
                            Get pre-qualified to find and match with eligible homes.
                        </li>
                        <li>
                            <div>
                                <CheckmarkInCircle height={18} width={18} />
                            </div>{' '}
                            Start currating your list of favorite homes.
                        </li>
                    </ul>
                </div>
            </div>
            <div className={classes.form}>
                <RegisterForm />
            </div>
        </div>
    );
}
