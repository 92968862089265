import { IconProps } from './types';

export default function DishwasherIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M22.25.75H1.75v22.5h20.5ZM.75.75h22.5M.75 23.25h22.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />

            <path
                d="M17.29 8H6.71a1 1 0 0 0-1 1v10.08a1 1 0 0 0 1 1h10.58a1 1 0 0 0 1-1V8.96a1 1 0 0 0-1-.96ZM9.25 11h5.5M5.33 4.5h13.42"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
