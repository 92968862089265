import { ORGS } from '@keyliving/shared-types';

/**
 * Type guard for checking if a string is a valid ORG
 *
 * @param str The string in question
 * @returns True or False
 */
export default function isOrg(str: string): str is ORGS {
    return Object.values<string>(ORGS).includes(str);
}
