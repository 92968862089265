import { zodResolver } from '@hookform/resolvers/zod';
import {
    Button,
    ErrorMessage,
    FormGroup,
    PasswordInput,
    TextInput,
    useToast,
} from '@keyliving/component-lib';
import classNames from 'classnames/bind';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { output } from 'zod';

import { SUPPORT_EMAIL } from '../../../constants';
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import { useRegisterMutation } from '../../../redux/modules/auth';
import classes from '../Auth.module.scss';
import { registerValidation } from './validation';

const cx = classNames.bind(classes);

type Inputs = output<typeof registerValidation>;

export default function RegisterForm() {
    const [, setSearchParams] = useSearchParams();
    const [registerUser, { error, isError, isLoading }] = useRegisterMutation();
    const { setAuthData } = useAuth();
    const toast = useToast();
    const { trackEvent } = useAnalytics();
    const fallbackErrorMsg = `We were not able to create an account based on the information provided. Please check that you have filled out the fields correctly or contact ${SUPPORT_EMAIL} for help.`;

    const {
        formState: { errors: validationErrors },
        handleSubmit,
        register,
    } = useForm<Inputs>({
        resolver: zodResolver(registerValidation),
    });

    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        const res = await registerUser({
            ...formdata,
            email: formdata.email.toLowerCase().trim(),
        });

        // We only have data if is successful
        if ('data' in res) {
            // TODO: handle action before register or redirect
            setAuthData({ data: res.data });
            trackEvent('registration');
            toast.success('You have created an account successfully');
            setSearchParams((params) => {
                // Close register modal...
                params.delete('authForm');

                // ...open welcome modal
                params.set('modal', 'welcome');

                return params;
            });
        }
    };

    return (
        <>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className={classes['two-col']}>
                    <div>
                        <TextInput
                            errors={validationErrors}
                            label="Full Name"
                            placeholder="Name"
                            {...register('name')}
                        />
                    </div>
                    <div>
                        <TextInput
                            errors={validationErrors}
                            label="Phone Number (optional)"
                            placeholder="Phone Number"
                            prefix="+1"
                            {...register('phone_number')}
                        />
                    </div>
                </FormGroup>
                <TextInput
                    autoCapitalize="none"
                    autoCorrect="off"
                    errors={validationErrors}
                    inputMode="email"
                    label="Email"
                    placeholder="Email"
                    {...register('email')}
                />
                <PasswordInput
                    errors={validationErrors}
                    label="Password"
                    placeholder="Password"
                    {...register('password')}
                    caption="At least one uppercase letter, one lowercase letter and one number"
                />

                {isError && (
                    <FormGroup>
                        <ErrorMessage
                            message={error?.message || fallbackErrorMsg}
                            name="form-error"
                        />
                    </FormGroup>
                )}

                <div className={cx(classes.submit)}>
                    <Button
                        isLoading={isLoading}
                        rounded
                        size="md"
                        theme="tertiary"
                        type="submit"
                        width="full"
                    >
                        Create Account
                    </Button>
                </div>
            </form>

            <div className={classes['form-switcher']}>
                Already a member?{' '}
                <button
                    className={classes['secondary-action']}
                    onClick={() => {
                        setSearchParams((params) => {
                            params.set('authForm', 'login');
                            return params;
                        });
                    }}
                    type="button"
                >
                    Login
                </button>
            </div>
        </>
    );
}
