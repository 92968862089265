import { useCookie, useGlobalAuth } from '@keyliving/component-lib';
import { AuthData, ORGS } from '@keyliving/shared-types';
import { getCurrentRootDomain, isOrg, isTokenExpired } from '@keyliving/utils';
import { useMemo } from 'react';

import { clearAuthDetails, selectAuthData, setAuthDetails } from '../redux/modules/auth';
import { useAppDispatch, useAppSelector } from './redux';
import useAnalytics from './useAnalytics';

const AUTH_COOKIE_NAME = 'hlpIsAuth';

export default function useAuth() {
    const { claims, token, user } = useAppSelector(selectAuthData);
    const dispatch = useAppDispatch();
    const { forgetUser } = useAnalytics();
    const { deleteCookie: deletePseudoAuthCookie, setCookie: setPseudoAuthCookie } = useCookie(
        AUTH_COOKIE_NAME,
        {
            domain: getCurrentRootDomain(),
        }
    );
    const { deleteToken: deleteAuthToken } = useGlobalAuth();
    const tokenExpired = isTokenExpired(claims?.exp ?? null);

    const org = useMemo<ORGS | null>(() => {
        const orgs = user?.org_codes;

        if (!orgs?.length) {
            return null;
        }

        const firstOrg = orgs[0];

        if (isOrg(firstOrg)) {
            return firstOrg;
        }

        return null;
    }, [user]);

    /**
     * Set auth data in state and set pseudo auth cookie for display purposes
     * on the website to emulate a logged in state.
     *
     * @param params.data User, claims and token data
     */
    const setAuthData = async ({ data }: { data: AuthData }) => {
        try {
            // Set user, claims and token in state
            dispatch(setAuthDetails(data));

            // Not used for actual auth. Just for display purposes on the website to show pseudo auth state.
            setPseudoAuthCookie('true', {
                expires: new Date(data.claims.exp * 1000),
            });

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const clearAuthData = () => {
        dispatch(clearAuthDetails());
        deleteAuthToken();
        sessionStorage.clear();
        forgetUser();

        // Clear pseudo auth state
        deletePseudoAuthCookie();

        return Promise.resolve();
    };

    return {
        user,
        claims,
        token,
        isLoggedIn: !!user && !tokenExpired,
        clearAuthData,
        org,
        setAuthData,
    };
}
