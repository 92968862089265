import classnames from 'classnames/bind';
import { ReactNode } from 'react';

import classes from './ContentBlock.module.scss';

const cx = classnames.bind(classes);

export interface ContentBlockProps {
    /** A valid `ReactNode` */
    children?: ReactNode;
    /** Modify the styles of the wrapping div */
    className?: string;
}

export default function ContentBlock({ children, className }: ContentBlockProps) {
    return (
        <div className={cx('content', className)} data-testid="content-block">
            {children}
        </div>
    );
}
