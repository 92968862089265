import { IconProps } from './types';

export default function LaundryIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M20.25.75H3.75a1.5 1.5 0 0 0-1.5 1.5v19.5a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V2.25a1.5 1.5 0 0 0-1.5-1.5ZM5.25 3.75h3M2.25 6.75h19.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M12 20.25a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M13.5 4.125a.375.375 0 0 1 0-.75M13.5 4.125a.375.375 0 0 0 0-.75M18 4.125a.375.375 0 0 1 0-.75M18 4.125a.375.375 0 0 0 0-.75"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M9.75 15A2.25 2.25 0 0 1 12 12.75"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
